@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

@layer base {
    h1 {
        @apply mx-4 my-6 text-3xl font-extrabold leading-9 tracking-tight text-slate-800 dark:text-slate-100 sm:text-4xl md:text-4xl md:leading-9;
    }

    .form-label {
        @apply my-2 text-xl font-extrabold leading-3 tracking-tight text-slate-700 dark:text-slate-100 sm:text-xl md:text-xl md:leading-3;
    }

    .experience-select .experience-select__control {
        @apply rounded-md border border-gray-300 bg-gray-50 hover:border-gray-400 dark:border-gray-600 dark:bg-slate-600 dark:hover:border-gray-500;
    }

    .experience-select .experience-select__control--is-focused {
        @apply border-cyan-500 shadow-none hover:border-cyan-500 dark:border-cyan-500 dark:hover:border-cyan-500;
    }

    .experience-select .experience-select__menu {
        @apply border-2 border-gray-300 bg-gray-100 dark:border-gray-600 dark:bg-gray-700;
    }

    .experience-select .experience-select__option {
        @apply bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-800;
    }
    /* .experience-select .experience-select__option--is-focused {
      @apply bg-gray-200 dark:bg-gray-800;
    } */

    .experience-select .experience-select__indicator-separator {
        @apply bg-gray-400;
    }

    .experience-select .experience-select__input-container,
    .experience-select .experience-select__placeholder,
    .experience-select .experience-select__single-value {
        @apply text-gray-600 dark:text-gray-200;
    }

    .experience-select input[type='text']:focus {
        box-shadow: none;
    }

    /* Multi-value container and labels */
    .experience-select-container .experience-select__multi-value {
        @apply rounded-md bg-slate-300 dark:bg-slate-700;
    }

    .experience-select-container .experience-select__multi-value__label {
        @apply text-red-800 dark:text-red-100;
    }

    /* Multi-value remove button and SVG */
    .experience-select-container .experience-select__multi-value__remove {
        @apply rounded-full bg-gray-500 hover:bg-gray-600 dark:bg-gray-700 dark:hover:bg-gray-800;
    }

    .experience-select-container .experience-select__multi-value__remove .css-tj5bde-Svg {
        @apply text-red-200 dark:text-red-400;
    }

    /* Clear and dropdown indicator */
    .experience-select-container .experience-select__clear-indicator,
    .experience-select-container .experience-select__dropdown-indicator {
        @apply text-gray-400 dark:text-gray-600;
    }

    .experience-select-container .experience-select__clear-indicator:hover,
    .experience-select-container .experience-select__dropdown-indicator:hover {
        @apply text-gray-500 dark:text-gray-500;
    }

    .experience-select-container .experience-select__clear-indicator .css-tj5bde-Svg,
    .experience-select-container .experience-select__dropdown-indicator .css-tj5bde-Svg {
        @apply fill-current;
    }
}

.dashboard-tooltip {
    @apply z-20 border border-gray-200 bg-gray-200 bg-sky-500/100 font-black text-gray-900 dark:border-none dark:bg-sky-500/100 dark:bg-opacity-100 dark:text-white;
}

/* https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied */
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition:
        background-color 600000s 0s,
        color 600000s 0s;
}

.nextui-navbar {
    z-index: 20;
}

.nextui-button-text {
    z-index: 20;
}
